import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { verifyOfficer } from './../features/fine/fineSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
}));

const CopDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      badgeNumber: '',
    },
  });

  const verifying = useSelector((state) => state.fine.verifying);
  const officer = useSelector((state) => state.fine.data.officer);

  const defaultValidation = (fieldName) => ({
    required: `${fieldName} is required`,
  });

  const onSubmit = (data) => {
    dispatch(verifyOfficer(data));
  };

  if (officer) {
    return (
      <Redirect
        to={{
          pathname: '/scan',
        }}
      />
    );
  }

  return (
    <Box className={classes.root} py={5}>
      <Container>
        <Typography color="secondary" variant="h2">
          Details
        </Typography>

        <Box pt={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
              <Controller
                name="badgeNumber"
                control={control}
                rules={defaultValidation('Badge Number')}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    fullWidth
                    error={invalid}
                    variant="outlined"
                    label="Badge Number"
                    placeholder="Enter the badge number"
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box
              pt={3}
              pb={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {verifying && <CircularProgress color="secondary" />}
              {!verifying && (
                <Button
                  fullWidth
                  color="secondary"
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  Continue
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default CopDetails;
