import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  verifying: false,
};

const officerCheck = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        badgeNumber: data.badgeNumber,
        name: 'A. B. C. Fernando',
      });
    }, Math.random() * 3000);
  });
};

const codeCheck = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        code: 'EX000001',
        title: 'Example Violation',
        description:
          'Vivamus aliquam pulvinar sem, eu pharetra lorem vestibulum sodales. Donec eleifend dui id mauris congue euismod.',
        amount: 1500,
      });
    }, Math.random() * 2000);
  });
};

export const verifyOfficer = createAsyncThunk(
  'fine/verifyOfficer',
  async (data) => {
    const response = await officerCheck(data);
    return response;
  }
);

export const verifyCode = createAsyncThunk('fine/verifyCode', async (data) => {
  const response = await codeCheck();
  return response;
});

export const fineSlice = createSlice({
  name: 'fine',
  initialState,
  reducers: {
    reset: (state) => Object.assign(state, initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(verifyOfficer.pending, (state) => {
      state.verifying = true;
    });
    builder.addCase(verifyOfficer.fulfilled, (state, action) => {
      state.data = {
        ...state.data,
        officer: action.payload,
      };
      state.verifying = false;
    });

    builder.addCase(verifyCode.pending, (state) => {
      state.verifying = true;
    });
    builder.addCase(verifyCode.fulfilled, (state, action) => {
      state.data = {
        ...state.data,
        violation: action.payload,
      };
      state.verifying = false;
    });
  },
});

export const { reset } = fineSlice.actions;

export default fineSlice.reducer;
