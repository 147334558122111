import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import QrReader from 'react-qr-reader';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { verifyCode } from './../features/fine/fineSlice';

const Scan = () => {
  const dispatch = useDispatch();

  const verifying = useSelector((state) => state.fine.verifying);
  const violation = useSelector((state) => state.fine.data.violation);

  const handleError = (err) => {
    console.error(err);
  };

  const handleScan = (data) => {
    if (data) dispatch(verifyCode(data));
  };

  if (violation) {
    return (
      <Redirect
        to={{
          pathname: '/summary',
        }}
      />
    );
  }

  if (verifying) {
    return (
      <Box
        py={20}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" variant="h1" color="secondary">
          Verifying
        </Typography>
        <Box mt={10}>
          <CircularProgress color="secondary" />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box my={3}>
        <Container>
          <Typography variant="h3">Scan the QR code</Typography>
        </Container>
      </Box>
      <Box>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      </Box>
      <Container></Container>
    </Box>
  );
};

export default Scan;
