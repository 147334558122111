import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { verifyUser } from './../features/user/userSlice';

const RegisterStepTwo = () => {
  const userData = useSelector((state) => state.user.data);
  const verifying = useSelector((state) => state.user.verifying);

  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      expDate: new Date(),
    },
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data) => {
    dispatch(verifyUser(data));
  };

  useEffect(() => {
    if (userData.status === 2) {
      history.push('/register/step-3');
    }
  }, [userData, history]);

  return (
    <Box py={5}>
      <Container>
        <Typography color="secondary" variant="h2">
          Driving License Details
        </Typography>
        <Box py={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
              <TextField
                fullWidth
                value={userData.licenceNumber}
                variant="outlined"
                label="License Number"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box mb={3}>
                <Controller
                  name="expDate"
                  control={control}
                  rules={{}}
                  render={({ field, fieldState: { error, invalid } }) => (
                    <KeyboardDatePicker
                      error={invalid}
                      id="date-picker-dialog"
                      label="License Expiration Date"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      disablePast
                      fullWidth
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      {...field}
                    />
                  )}
                />
              </Box>
            </MuiPickersUtilsProvider>

            <Box mb={3}>
              <Button variant="contained" component="label">
                Upload License Image
                <input type="file" hidden />
              </Button>
              <FormHelperText>
                The image will be used to verify the entered details. Please
                make sure the image is clear.
              </FormHelperText>
            </Box>

            <Box mb={3}>
              <Box display="flex" justifyContent="space-between">
                <Button variant="contained" component="label">
                  Upload NIC Front
                  <input type="file" hidden />
                </Button>
                <Button variant="contained" component="label">
                  Upload NIC Back
                  <input type="file" hidden />
                </Button>
              </Box>
              <FormHelperText>
                The images will be used to verify your identity. Please make
                sure the images are clear.
              </FormHelperText>
            </Box>

            <Box
              py={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {verifying && <CircularProgress color="secondary" />}
              {!verifying && (
                <Button
                  fullWidth
                  color="secondary"
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  Continue
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default RegisterStepTwo;
