import { createTheme } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';
// import orange from '@material-ui/core/colors/orange';

// A custom theme for this app
const theme = createTheme({
  // palette: {
  //   primary: purple,
  //   secondary: orange,
  // },
});

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '3rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '5rem',
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '2rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '4rem',
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '1.4rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.4rem',
  },
};

export default theme;