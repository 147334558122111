import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { logout } from './features/user/userSlice';

// screens
import Home from './screens/Home';
import Scan from './screens/Scan';
import CopDetails from './screens/CopDetails';
import Summary from './screens/Summary';
import Login from './screens/Login';
import RegisterStepOne from './screens/Register-1';
import RegisterStepTwo from './screens/Register-2';
import RegisterStepThree from './screens/Register-3';
import Pay from './screens/Pay';
import Thankyou from './screens/Thankyou';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    marginTop: -1,
  },
  content: {
    flexGrow: 1,
  },
  page: {
    minWidth: '100%',
  },
}));

function PrivateRoute({ children, ...rest }) {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/register',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  const classes = useStyles();
  const { status } = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Router>
      <div className={classes.root}>
        {status === 3 && (
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" noWrap style={{ flexGrow: 1 }} onClick={() => window.location.pathname = '/'}>
                Easy Fine
              </Typography>
              <Button onClick={handleLogout} color="inherit">
                Logout
              </Button>
            </Toolbar>
          </AppBar>
        )}

        <main className={classes.content}>
          {status === 3 && <div className={classes.toolbar} />}

          <Box className={classes.page}>
            <Switch>
              <PrivateRoute path="/" exact>
                <Home />
              </PrivateRoute>
              <PrivateRoute path="/details">
                <CopDetails />
              </PrivateRoute>
              <PrivateRoute path="/scan">
                <Scan />
              </PrivateRoute>
              <PrivateRoute path="/summary">
                <Summary />
              </PrivateRoute>
              <PrivateRoute path="/pay">
                <Pay />
              </PrivateRoute>
              <PrivateRoute path="/thanks">
                <Thankyou />
              </PrivateRoute>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/register" exact>
                <RegisterStepOne />
              </Route>
              <PrivateRoute path="/register/step-2">
                <RegisterStepTwo />
              </PrivateRoute>
              <PrivateRoute path="/register/step-3">
                <RegisterStepThree />
              </PrivateRoute>
            </Switch>
          </Box>
        </main>
      </div>
    </Router>
  );
}

export default App;
