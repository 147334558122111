import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { reset } from './../features/fine/fineSlice';

const Pay = () => {
  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      fullName: '',
      ccNumber: '',
    },
  });


  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = () => {
    dispatch(reset());
    history.push('/thanks');
  };

  const defaultValidation = (fieldName) => ({
    required: `${fieldName} is required`,
  });

  return (
    <Box py={1}>
      <Container>
        <Box py={3}>
          <Typography color="secondary" variant="h1">
            Pay now
          </Typography>
        </Box>
        <Box py={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
              <Controller
                name="fullName"
                control={control}
                rules={defaultValidation('Full Name')}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    fullWidth
                    error={invalid}
                    variant="outlined"
                    label="Full Name"
                    placeholder="Enter your full name"
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box mb={3}>
              <Controller
                name="ccNumber"
                control={control}
                rules={defaultValidation('Credit card number')}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    fullWidth
                    error={invalid}
                    variant="outlined"
                    label="Credit card number"
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box
              pt={3}
              pb={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
                <Button
                  fullWidth
                  color="secondary"
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default Pay;
