import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  header: {},
  wrapper: {
    position: 'relative',
  },
  t1: {
    fontSize: '1.2rem',
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  t2: {
    fontSize: '2rem',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  t3: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.getContrastText(theme.palette.primary.light),
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

const Home = () => {
  const user = useSelector((state) => state.user.data);
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.root} pb={5}>
      <div className={classes.wrapper}>
        <Box bgcolor="primary.light" px={3} pt={8} pb={5}>
          <Typography className={classes.t1}>Welcome</Typography>
          <Typography className={classes.t2}>{user.fullName}</Typography>
          <Typography className={classes.t3}>{user.licenceNumber}</Typography>
        </Box>
        <Box py={5}>
          <Container>
            <Box>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
              blanditiis tenetur unde suscipit, quam beatae rerum inventore
              consectetur, neque doloribus, cupiditate numquam dignissimos
              laborum fugiat deleniti? Eum quasi quidem quibusdam.
            </Box>
          </Container>
        </Box>
        <Box>
          <Container>
            <Button fullWidth variant="contained" color="secondary" onClick={() => history.push('/details')}>Settle Fine</Button>
          </Container>
        </Box>
      </div>
    </Box>
  );
};

export default Home;
