import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/DoneOutline';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh',
  },
  icon: {
    fontSize: 56,
  },
}));

const Thankyou = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Box
      className={classes.root}
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container>
        <Box py={3} display="flex" justifyContent="center" alignItems="center">
          <Icon className={classes.icon} color="secondary" />
        </Box>
        <Typography align="center" variant="h1" gutterBottom color="secondary">
          Thank you!
        </Typography>
        <Typography align="center" paragraph gutterBottom>
          Your payment has been processed. A confirmation message will be
          dispatched soon.
        </Typography>
      </Container>
      <Box mt={4} pb={4}>
        <Button
          color="primary"
          onClick={() => {
            history.push('/');
          }}
        >
          Go back
        </Button>
      </Box>
    </Box>
  );
};

export default Thankyou;
