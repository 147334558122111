import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/DoneOutline';
import Button from '@material-ui/core/Button';

import { completeVerification } from './../features/user/userSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  icon: {
    fontSize: 56,
  },
}));

const RegisterStepThree = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Box
      className={classes.root}
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container>
        <Box py={3} display="flex" justifyContent="center" alignItems="center">
          <Icon className={classes.icon} color="secondary" />
        </Box>
        <Typography align="center" variant="h1" gutterBottom color="secondary">
          Thank you!
        </Typography>
        <Typography align="center" paragraph gutterBottom>
          Your account is being verified. You will be notified as soon as the
          verification process is complete.
        </Typography>
      </Container>
      <Box mt={4}>
        <Typography gutterBottom variant="caption">
          Prototype only
        </Typography>
        <Button
          size="small"
          onClick={() => {
            dispatch(completeVerification());
            history.push('/');
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default RegisterStepThree;
