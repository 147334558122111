import React from 'react';
import { Redirect } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { registerUser } from './../features/user/userSlice';

const RegisterStepOne = () => {
  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      // fullName: 'K L Faber',
      // emailAddress: 'krisfaber@gmail.com',
      // phoneNumber: '+94779715186',
      // licenceNumber: 'B12345667',
      // address: '42, Lotus Grove, Colombo 04',
      // password: '1111111111',
      fullName: '',
      emailAddress: '',
      phoneNumber: '',
      licenceNumber: '',
      address: '',
      password: '',
    },
  });

  const loading = useSelector((state) => state.user.loading);
  const userStatus = useSelector((state) => state.user.data.status);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(registerUser(data));
  };

  const defaultValidation = (fieldName) => ({
    required: `${fieldName} is required`,
  });

  if (userStatus) {
    return (
      <Redirect
        to={{
          pathname: '/register/step-2',
        }}
      />
    );
  }

  return (
    <Box py={1}>
      <Container>
        <Box py={3}>
          <Typography color="secondary" variant="h1">
            Register
          </Typography>
        </Box>
        <Box py={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb={3}>
              <Controller
                name="fullName"
                control={control}
                rules={defaultValidation('Full Name')}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    fullWidth
                    error={invalid}
                    variant="outlined"
                    label="Full Name"
                    placeholder="Enter your full name"
                    helperText={
                      error?.message ??
                      "Name must be exactly as it appears on the driver's licence"
                    }
                    {...field}
                  />
                )}
              />
            </Box>

            <Box mb={3}>
              <Controller
                name="emailAddress"
                control={control}
                rules={defaultValidation('E-mail')}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    fullWidth
                    error={invalid}
                    variant="outlined"
                    label="E-mail"
                    type="email"
                    placeholder="Enter your e-mail address"
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box mb={3}>
              <Controller
                name="password"
                control={control}
                rules={defaultValidation('Password')}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    fullWidth
                    error={invalid}
                    variant="outlined"
                    label="Password"
                    type="password"
                    placeholder="Enter your passsword"
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box mb={3}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={defaultValidation('Phone Number')}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    fullWidth
                    error={invalid}
                    variant="outlined"
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box mb={3}>
              <Controller
                name="licenceNumber"
                control={control}
                rules={defaultValidation('Drivers Licence Number')}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    fullWidth
                    error={invalid}
                    variant="outlined"
                    label="Drivers Licence Number"
                    placeholder="Enter your drivers licence number address"
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box mb={3}>
              <Controller
                name="address"
                control={control}
                rules={defaultValidation('Address')}
                render={({ field, fieldState: { error, invalid } }) => (
                  <TextField
                    fullWidth
                    error={invalid}
                    multiline
                    rows={3}
                    variant="outlined"
                    label="Address"
                    placeholder="Enter your address"
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box
              pt={3}
              pb={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {loading && <CircularProgress color="secondary" />}
              {!loading && (
                <Button
                  fullWidth
                  color="secondary"
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  Register
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default RegisterStepOne;
