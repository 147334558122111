import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
}));

const Summary = () => {
  const classes = useStyles();
  const history = useHistory();

  const violation = useSelector((state) => state.fine.data.violation);
  const officer = useSelector((state) => state.fine.data.officer);

  return (
    <Box className={classes.root} py={5}>
      <Container>
        <Typography color="secondary" variant="h2">
          Summary
        </Typography>

        <Box pt={4}>
          <Typography variant="h5" gutterBottom>
            Fine details
          </Typography>
          <Typography variant="h6">{violation.title}</Typography>
          <Typography gutterBottom>{violation.description}</Typography>
          <Typography
            variant="h6"
            color="secondary"
          >{`Rs. ${violation.amount}`}</Typography>
        </Box>

        <Box pt={4}>
          <Typography variant="h5" gutterBottom>
            Officer details
          </Typography>
          <Typography>{officer.badgeNumber}</Typography>
          <Typography>{officer.name}</Typography>
        </Box>

        <Box mt={5}>
          <Button
            fullWidth
            color="primary"
            size="large"
            variant="contained"
            type="button"
            onClick={() => history.push('/pay')}
          >
            Pay now
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Summary;
