import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  loggedIn: false,
  loading: false,
  verifying: false,
};

const register = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        ...data,
        status: 1,
      });
    }, Math.random() * 4000);
  });
};

const verify = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        ...data,
        status: 2,
      });
    }, Math.random() * 6000);
  });
};

export const registerUser = createAsyncThunk('user/register', async (data) => {
  const response = await register(data);
  return response;
});

export const verifyUser = createAsyncThunk('user/verify', async (data) => {
  const response = await verify(data);
  return response;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: state => Object.assign(state, initialState),
    completeVerification: (state) => {
      state.data.status = 3;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.loading = true
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(registerUser.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = action.payload;
      state.loggedIn = true;
      state.loading = false;
    });


    builder.addCase(verifyUser.pending, (state) => {
      state.verifying = true
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(verifyUser.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = {
        ...state.data,
        ...action.payload,
      }
      state.verifying = false;
    });

  },
});

// Action creators are generated for each case reducer function
export const { completeVerification, logout } = userSlice.actions;

export default userSlice.reducer;
